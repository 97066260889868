var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"all-exams-page mx-2"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[(_vm.nextExam)?_c('div',[_c('h3',{staticClass:"mt-2"},[_vm._v("Next Exam")]),_c('v-card',{staticClass:"my-2",attrs:{"color":"primary"}},[_c('v-list-item',{attrs:{"dark":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.nextExam.subject.name))]),_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.nextExam.title))]),(_vm.nextExam.description)?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.TextHelper.linkify(_vm.nextExam.description))}},[_vm._v(" }}")]):_vm._e(),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[(
                    _vm.moment(_vm.nextExam.start_datetime).date() ==
                    _vm.moment(_vm.nextExam.end_datetime).date()
                  )?_c('span',[_vm._v(_vm._s(_vm.moment(_vm.nextExam.start_datetime).format("lll"))+" - "+_vm._s(_vm.moment(_vm.nextExam.end_datetime).format("hh:mm A")))]):_c('span',[_vm._v(_vm._s(_vm.moment(_vm.nextExam.start_datetime).format("lll"))+" - "+_vm._s(_vm.moment(_vm.nextExam.end_datetime).format("lll")))])])],1),_c('v-list-item-icon',{staticClass:"align-self-center"},[(
                  (_vm.nextExam.created_by.id == _vm.currentUser.id ||
                    _vm.currentUser.is_admin) &&
                  !_vm.moment().isAfter(_vm.moment(_vm.nextExam.start_datetime))
                )?_c('intract-drop-down-menu',{attrs:{"options-list":_vm.getDropdownOptions(_vm.nextExam)}}):_vm._e()],1)],1)],1)],1):_vm._e(),_c('div',[_c('h3',{staticClass:"mt-2"},[_vm._v("Upcoming Exams")])]),(_vm.shouldProceed)?_c('intract-smart-list',{ref:"exams-smart-list",attrs:{"disable-search":"","paginated":"","endpoint":_vm.examsEndpoint},on:{"itemsReceived":_vm.initNextExam},scopedSlots:_vm._u([{key:"list-item",fn:function({ item: exam }){return [_c('v-card',{staticClass:"my-2"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(exam.subject.name))]),_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(exam.title))]),(exam.description)?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.TextHelper.linkify(exam.description))}}):_vm._e(),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[(
                      _vm.moment(exam.start_datetime).date() ==
                      _vm.moment(exam.end_datetime).date()
                    )?_c('span',[_vm._v(_vm._s(_vm.moment(exam.start_datetime).format("lll"))+" - "+_vm._s(_vm.moment(exam.end_datetime).format("hh:mm A")))]):_c('span',[_vm._v(_vm._s(_vm.moment(exam.start_datetime).format("lll"))+" - "+_vm._s(_vm.moment(exam.end_datetime).format("lll")))])])],1),_c('v-list-item-icon',{staticClass:"align-self-center"},[(
                    (exam.created_by.id == _vm.currentUser.id ||
                      _vm.currentUser.is_admin) &&
                    !_vm.moment().isAfter(_vm.moment(exam.start_datetime))
                  )?_c('intract-drop-down-menu',{attrs:{"options-list":_vm.getDropdownOptions(exam)}}):_vm._e()],1)],1)],1)]}},{key:"paginated-no-items-found",fn:function(){return [_c('p',{staticClass:"mb-2"},[_vm._v("No Exams Found!")]),(!_vm.currentUser.is_student)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.createEditExamSheet.visible = true}}},[_vm._v("Create An Exam")]):_vm._e()]},proxy:true}],null,false,1221510045)}):_vm._e()],1)],1),(_vm.formFields)?_c('intract-create-edit-sheet',{key:"exam-create-edit-sheet",ref:"exam-create-edit-sheet",attrs:{"title":_vm.createEditExamSheet.editId ? 'Edit Exam' : 'Create Exam',"description":"Link an online meeting from Google Meet, Zoom or your favourite video conferencing platform.","persistent":"","visible":_vm.createEditExamSheet.visible,"fields":_vm.formFields,"endpoint":_vm.endpoints.examPosts,"edit-id":_vm.createEditExamSheet.editId,"data-object":_vm.createEditExamSheet.obj,"create-success-message":"Exam successfully scheduled!","edit-success-message":"Exam successfully edited!"},on:{"close":function($event){_vm.createEditExamSheet.visible = false;
      _vm.createEditExamSheet.editId = null;},"objectCreated":_vm.onRefresh,"updateObject":(obj) => (_vm.createEditExamSheet.obj = obj)}}):_vm._e(),_c('v-fab-transition',[(!_vm.isLoading && !_vm.currentUser.is_student)?_c('v-btn',{attrs:{"color":"primary","dark":"","right":"","bottom":"","fixed":"","fab":""},on:{"click":function($event){_vm.createEditExamSheet.visible = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('choose-room-dialog',{attrs:{"visible":_vm.showRoomDialog},on:{"roomSelected":(r) => {
        _vm.selectedRoom = r;
        _vm.showRoomDialog = false;
      }}}),_c('confirmation-dialog',{attrs:{"title":"Are you sure you want to delete this exam?","description":"This action cannot be undone.","visible":_vm.showDeleteDialog,"delete-dialog":""},on:{"successCallback":_vm.deleteExam,"failureCallback":function($event){_vm.showDeleteDialog = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }